import React from "react";

function Vmc() {
    return (
        <section className='section bg-c-light border-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mb-5 text-center'>
                            <h3 className='main-heading'>Vision, Mission & Values</h3>
                            <div className='underline mx-auto'></div>
                        </div>
                        <div className='col-md-4 text-center'>
                            <h6>Vision</h6>
                            <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                            </p>
                        </div>
                        <div className='col-md-4 text-center'>
                            <h6>Mission</h6>
                            <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                            </p>
                        </div>
                        <div className='col-md-4 text-center'>
                            <h6>Values</h6>
                            <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                            </p>
                        </div>                        
                    </div>
                </div>
            </section>
    );
}

export default Vmc;