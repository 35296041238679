import React from "react";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Ain from '../../images/ain.svg';

function AinNav(){
    return (
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme='dark' sticky="top"> 
        <Container fluid>
            <img src={Ain} className="navbar-brand" alt="Brand logo" height="36"/>
            <Navbar.Brand href="/">Tune my network</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>

            <NavDropdown title="Product" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Connected</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Service" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/career">Career</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="#" disabled>Link</Nav.Link>
            </Nav>
            <Form className="d-flex">
                <Form.Control type="search" placeholder="Search" className="me-2" aria-label="Search"/>
                <Button variant="outline-success">Login</Button>
            </Form>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}

export default AinNav;