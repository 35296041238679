import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import S1 from '../../images/01.png';
import S2 from '../../images/02.png';
import S3 from '../../images/03.png';
import S4 from '../../images/04.png';
import S5 from '../../images/05.png';
import S6 from '../../images/06.png';
import S7 from '../../images/07.png';
import S8 from '../../images/08.png';
import S9 from '../../images/09.png';
import S10 from '../../images/10.png';
import S11 from '../../images/11.png';
import S12 from '../../images/12.png';
import S13 from '../../images/13.png';
import S14 from '../../images/14.png';
import S101 from '../../images/101.png';
import S102 from '../../images/102.png';

function Slider() {
    return (
      <Carousel>

    <Carousel.Item>
      <img src={S101} className="d-block w-100" alt="Third slide"/>
        <Carousel.Caption>
          <h3>Business possiblities</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

    <Carousel.Item>
      <img src={S102} className="d-block w-100" alt="Third slide"/>
        <Carousel.Caption>
          <h3>Business possiblities</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

    <Carousel.Item>
      <img src={S1} className="d-block w-100" alt="Third slide"/>
        <Carousel.Caption>
          <h3>Business possiblities</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

    <Carousel.Item>
      <img src={S2} className="d-block w-100" alt="Third slide"/>
        <Carousel.Caption>
          <h3>Business possiblities</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

    <Carousel.Item>
      <img src={S3} className="d-block w-100" alt="Third slide"/>
        <Carousel.Caption>
          <h3>Security & compliance</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S4} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Responsible AI & Machine learning</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>


      
      <Carousel.Item>
      <img src={S5} className="d-block w-100" alt="Second slide"/>
        <Carousel.Caption>
          <h3>Responsible AI</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={S6} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Cyber Security</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S7} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Data engineering</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S8} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Business dashboard</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S9} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Datacenter</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S10} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Datacenter</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S11} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Datacenter</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S12} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Datacenter</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S13} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Datacenter</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S14} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Datacenter</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>

    </Carousel>
    );

}

export default Slider;