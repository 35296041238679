
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import AinNav from './components/inc/AinNav';
import Footer from './components/inc/Footer';
import Home from './components/pages/Home';
import AboutUs from './components/pages/AboutUs';
import Contact from './components/pages/Contact';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';


function App() {
  return (
    <Router>
    <div>
    <AinNav/>

      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/about' element={<AboutUs/>} />
      </Routes>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
