import React from "react";
import { Link } from "react-router-dom";
import Ain from '../../images/ain.svg';

function Footer() {
    return (
        <section className="section footer bg-dark text-white">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <img src={Ain} className="navbar-brand" alt="Brand logo" height="16"/>
                        <span>&nbsp;&nbsp;</span>
                        <Link className="navbar-brand" to="#">Tune my network</Link>
                        <hr/>
                        <p className="text-white">Let's strive and grow together.</p>
                    </div>
                    <div className="col-md-4">
                        <h6>Quick links</h6>
                        <hr/>
                        <div><Link to="">Home</Link></div>
                        <div><Link to="/about">About</Link></div>
                        <div><Link to="/contact">Contact</Link></div>
                        <div><Link to="/blog">Blog</Link></div>
                    </div>
                    <div className="col-md-4">
                        <h6>Contact Information</h6>
                        <hr/>
                        <div><p className="text-white mb-1"></p></div>
                        <div><p className="text-white mb-1"></p></div>
                        <div><p className="text-white mb-1">Email: contact@tunemynetwork.com</p></div>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-4">© 2024 Tune my network. All Rights Reserved</div>
                <div className="col-md-4"></div>
                <div className="col-md-4"></div>
                </div>
            </div>
        </section>
    );
}

export default Footer;