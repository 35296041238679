import React from "react";

function Contact() {
    return (
        <div>
            <section className="py-4 bg-info">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 my-auto">
                            <h4>Contact</h4>
                        </div>
                        <div className="col-md-8 my-auto">
                            <h6 className="float-end">
                                Home / Contact
                            </h6>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                <h6>Contact Form</h6>
                                <hr/>
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">First Name</label>
                                    <input type="text" className="form-control" placeholder="Enter First name"/>
                                </div>
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">Last Name</label>
                                    <input type="text" className="form-control" placeholder="Enter Last name"/>
                                </div>
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">Email</label>
                                    <input type="text" className="form-control" placeholder="Enter Email"/>
                                </div>
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">Phone</label>
                                    <input type="text" className="form-control" placeholder="Enter Phone"/>
                                </div>
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">Message</label>
                                    <textarea rows="3" className="form-control" placeholder="Type your message"/>
                                </div>
                                <div className="form-group py-3">
                                    <button type="button" className="btn btn-primary shadow w-100">Send Message</button>
                                </div>
                                </div>
                                <div className="col-md-6 border-start">
                                    <h5 className="main-heading">Address Information</h5>
                                    <div className="underline"></div>
                                    <p>#XXX, San Jose, CA 95148, USA</p>
                                    <p>Phone: +1 (408) 206-XXXX</p>
                                    <p>Email: contact@accelerateitnow.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Contact;